import React, { useState } from 'react';
import { Container } from '../../global/styles'
import i18n from '../i18n';
import { InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    Img,
    ImgWrapper,
    NavMenu,
    NavItem,
    NavLinks,
} from './InfoSection.elements';

const buttonSelectedMap = [
    {
        title: 'Main Skills',
        text: 
            <>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.skills.devops.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>{i18n.t('home.components.infoSection.skills.devops.subtitle')}</Subtitle>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.skills.frontend.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>{i18n.t('home.components.infoSection.skills.frontend.subtitle')}</Subtitle>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.skills.backend.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>{i18n.t('home.components.infoSection.skills.backend.subtitle')}</Subtitle>
                </div>
            </>
    },
    {
        title: 'Experience',
        text: 
            <>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.experience.daitan.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>2020 - 2021</Subtitle>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.experience.encora.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>2022 - Current</Subtitle>
                </div>
            </>
    },
    {
        title: 'Education',
        text: 
            <>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.education.unip.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>2018 - 2022</Subtitle>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>{i18n.t('home.components.infoSection.education.mackenzie.topline')}</TopLine> 
                    <Subtitle lightTextDesc={false}>2022 - Current</Subtitle>
                </div>
            </>
    },
    {
        title: 'Certification',
        text: 
            <>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>AWS Certified Solutions Architect – Associate</TopLine> 
                    <Subtitle lightTextDesc={false}>MAR - 2026</Subtitle>
                </div>
                <div style={{marginBottom: "16px"}}>
                    <TopLine lightTopLine={false}>SRE Foundation</TopLine> 
                    <Subtitle lightTextDesc={false}>APR - 2025</Subtitle>
                </div>
            </>
    },
]

const InfoSection = ({lightBg, imgStart, lightTopLine, lightTextDesc, buttonLabel, description, headLine, lightText, topLine, img, alt, start, dividerColor}) => {
    const [selectedButton, setSelectedButton] = useState(0);
    
    return (
        <>
            <InfoSec id='info' lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                {/* <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine> */}
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                                <NavMenu>
                                    <NavItem selected={selectedButton === 0}>
                                        <NavLinks onClick={() => setSelectedButton(0)} selected={selectedButton === 0}>
                                            {i18n.t('home.components.infoSection.buttons.mainSkills')}
                                        </NavLinks>
                                    </NavItem>
                                    <NavItem selected={selectedButton === 1}>
                                        <NavLinks smooth={true} duration={500} spy={true} exact="true" offset={-80} onClick={() => setSelectedButton(1)} selected={selectedButton === 1}>
                                            {i18n.t('home.components.infoSection.buttons.experience')}
                                        </NavLinks>
                                    </NavItem>
                                    <NavItem selected={selectedButton === 2}>
                                        <NavLinks onClick={() => setSelectedButton(2)} selected={selectedButton === 2}>
                                            {i18n.t('home.components.infoSection.buttons.education')}
                                        </NavLinks>
                                    </NavItem>
                                    <NavItem selected={selectedButton === 3}>
                                        <NavLinks onClick={() => setSelectedButton(3)} selected={selectedButton === 3}>
                                            {i18n.t('home.components.infoSection.buttons.certification')}
                                        </NavLinks>
                                    </NavItem>
                                </NavMenu>
                                {buttonSelectedMap[selectedButton].text}
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={require('../../assets/photo-black.jpg')} alt={alt}/>
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection
