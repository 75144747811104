import styled from 'styled-components';

export const Wrapper = styled.div`
padding: 100px 0 232px 0 ;
display: flex;
flex-direction: column;
justify-content: flex-start;
background: #fff;
align-items: flex-start
width: 100%;
max-width: 1300px;
margin-right: auto;
margin-left: auto;
padding-right: 50px;
padding-left: 50px;
`;

export const TextWrapper = styled.div`
display: flex;
padding: 0 0% 20px 0%;
flex-wrap: wrap;
flex-direction: column;
align-items: flex-start;
font-size: 16px;
text-align: justify;
`;