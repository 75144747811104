import React, {  } from 'react';
import { TextInput, TextLabel, TextArea } from './TextInput.elements';




const TextField = ({id, title, value, onChange, placeholder, type, required, multiline, errorMessage, invalid, ...rest}) => {
  return (
    multiline ?
    <TextLabel>
      {title}
      <TextArea id={id} type={type} value={value} onChange={onChange} placeholder={placeholder} required={required} autoCorrect={false} rows={3} {...rest}/>
    </TextLabel>
    :
    <TextLabel>
      {title} {invalid && <TextLabel style={{color: "#e04e5c"}}>*</TextLabel>}
      <TextInput id={id} type={type} value={value} onChange={onChange} placeholder={placeholder} required={false} formNoValidate invalid={invalid} {...rest}/>
      {(invalid === true && errorMessage !== '') && <TextLabel style={{color: "#e04e5c"}}>{errorMessage}</TextLabel>}
    </TextLabel>
  );
}

export default TextField;