import React from 'react';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  BtnLink,
  Button,
  PricingCard2,
  PricingCard3
} from './ProjectsSection.elements';
import i18n from '../i18n';

function ProjectsSection() {
  return (
    <IconContext.Provider value={{ color: '#3023ae', size: 64 }}>
      <PricingSection id='projects'>
        <PricingWrapper>
          <PricingHeading>{i18n.t('home.components.projectsSection.projects')}</PricingHeading>
          <PricingContainer>
            <PricingCard photo='../../assets/midas.png'>
              <PricingCardInfo>
                {/* <PricingCardCost>DevOps Engineering</PricingCardCost>
                <PricingCardLength>Ideal para quem navega nas redes sociais e trafega casualmente pela internet.</PricingCardLength>
                <Divider /> */}
              </PricingCardInfo>
              <BtnLink href='https://www.midasinternet.com.br' target="_blank" rel="noopener noreferrer" smooth={true} duration={500} spy={true} exact="true">
                  <Button primary>{i18n.t('home.components.projectsSection.view')}</Button>
              </BtnLink>
            </PricingCard>
            <PricingCard2>
              <PricingCardInfo>
                {/* <PricingCardCost>Website Development</PricingCardCost>
                <PricingCardLength>Ideal para quem navega nas redes sociais e trafega casualmente pela internet.</PricingCardLength>
                <Divider /> */}
              </PricingCardInfo>
              <BtnLink href='https://sds2-felipesouza.netlify.app/' target="_blank" rel="noopener noreferrer" smooth={true} duration={500} spy={true} exact="true">
                  <Button primary>{i18n.t('home.components.projectsSection.view')}</Button>
              </BtnLink>
            </PricingCard2>
            <PricingCard3>
              <PricingCardInfo>
                {/* <PricingCardCost>API Development</PricingCardCost>
                <PricingCardLength>Ideal para quem navega nas redes sociais e trafega casualmente pela internet.</PricingCardLength>
                <Divider /> */}
              </PricingCardInfo>
              <BtnLink href='https://www.figma.com/proto/c5Qc431SVYr8g0dNcy5sgq/TCC---UI?scaling=scale-down&page-id=0%3A1&starting-point-node-id=104%3A17&show-proto-sidebar=1&node-id=1%3A2' target="_blank" rel="noopener noreferrer" smooth={true} duration={500} spy={true} exact="true">
                  <Button primary>{i18n.t('home.components.projectsSection.view')}</Button>
              </BtnLink>
            </PricingCard3>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default ProjectsSection;