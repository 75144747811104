import React from 'react';
import { Heading, 
         Subtitle, 
         TextWrapper,
         Container,
         InfoRow,
         InfoColumn,
         ImgWrapper,
         Img,
         TopLine,
         FlexContainer,
         HeroSec,
         SocialIcons,
         SocialIconLink
} from '../heroSection/HeroSection.elements';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import i18n from '../i18n';


const HeroSection = () => {
    return (
            <HeroSec>
                <Container>
                    <InfoRow imgStart={false}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={false}>{i18n.t('home.components.heroSection.welcome')}</TopLine>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Heading lightText={false}>{i18n.t('home.components.heroSection.be')}</Heading>
                                    <Heading lightText={true}>Felipe</Heading>
                                    <Heading lightText={false}>Souza</Heading>
                                </div>
                                <Subtitle lightTextDesc={false}> 
                                    {i18n.t('home.components.heroSection.subtitle')}
                                </Subtitle>
                                <FlexContainer>
                                    <SocialIcons>
                                        <SocialIconLink href="https://github.com/fsouza-fs" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                                            <FaGithub size="35px"/>
                                        </SocialIconLink>
                                        <SocialIconLink href="https://www.linkedin.com/in/felipe-souza-673b95173/" target="_blank" rel="noopener noreferrer" aria-label="Linkedin">
                                            <FaLinkedin size="35px"/>
                                        </SocialIconLink>
                                        <SocialIconLink href="https://www.instagram.com/feeh_souza79" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                            <FaInstagram size="35px"/>
                                        </SocialIconLink>
                                    </SocialIcons>
                                </FlexContainer>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn style={{marginBottom: "0px"}}>
                            <ImgWrapper start={false}>
                                <Img src={require('../../assets/photo.png')} alt="Text"/>
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </HeroSec>
    )
}

export default HeroSection
