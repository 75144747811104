import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import i18n from "./components/i18n";
import GlobalStyle from "./global/styles";
import RoutesRoute from "./routes";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ToastContainer />
      <GlobalStyle />
      <RoutesRoute />
    </I18nextProvider>
  );
}

export default App;
