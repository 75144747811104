import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Wrapper from './components/wrapper/Wrapper'
import Home from './pages/home/Home';
import TermsOfService from './pages/privacy/Privacy';

const RoutesRoute = () => {
    return (
        <Router basename="/">
            <Wrapper>
                <Routes>
                    <Route element={<Home />}
                        path="/"
                        exact 
                    />
                    <Route element={<TermsOfService />}
                        path="/privacy"
                        exact 
                    />
                </Routes>
            </Wrapper>
        </Router>
    );
}

export default RoutesRoute;

