import React, { useState } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMoreRounded,ExpandMore } from '@material-ui/icons';
import i18n from '../i18n';

import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavbarExternalLink } from './Navbar.elements';
import { Us, Br } from "react-flags-select";

const Navbar = ({transparency, sticky}) => {
    const [click, setClick] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleClick = () => setClick(!click);

    const handleResize = () => {
        if (window.innerWidth < 960) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }

    const toggleHome = () => {
        scroll.scrollToTop();
        closeMobileMenu();
    }
    const toggleFooter = () => {
        scroll.scrollToBottom();
        closeMobileMenu();
    }

    const closeMobileMenu = () => setClick(false);

    const changeNavBackground = () => {
        window.scrollY >= 80 
        ? setNavbar(true)
        : setNavbar(false);
    }

    window.addEventListener('scroll', changeNavBackground);
    window.addEventListener('scroll', handleResize);
    window.addEventListener('resize', handleResize);

    const setLanguage = (lang) => {
        localStorage.setItem('lang', lang);
        window.location.reload(false);
    }

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <Nav navbar={navbar} transparency={transparency} sticky={sticky}>
                    <NavbarContainer>
                        <NavLogo to="/" onClick={closeMobileMenu}>
                            Felipe Souza
                        </NavLogo>
                        <MobileIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>
                        <NavMenu click={click}>
                            <NavItem>
                                <NavLinks to='#' onClick={toggleHome}>
                                    {i18n.t('home.components.navbar.home')}
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='pricing' smooth={true} duration={500} spy={true} exact="true" offset={-80} onClick={closeMobileMenu}>
                                    {i18n.t('home.components.navbar.services')}
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='projects' smooth={true} duration={500} spy={true} exact="true" offset={-80} onClick={closeMobileMenu}>
                                    {i18n.t('home.components.navbar.portifolio')}
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='contact' onClick={toggleFooter}>
                                    {i18n.t('home.components.navbar.contact')}
                                </NavLinks>
                            </NavItem>
                            <NavItem expanded onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)} aria-expanded={dropdown ? "true" : "false"}>
                                { isMobile ?
                                    <Accordion style={{boxShadow: 'none', padding: '0.5rem 2rem', background: '#0e0e0e', width: '100%'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMore htmlColor='#fff'/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            background: '#0e0e0e',
                                            fontFamily: 'poppins,sans-serif',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            padding: '0'
                                        }}
                                        >
                                        <Typography 
                                            style={{
                                                fontFamily: 'poppins,sans-serif',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '18px'
                                            }}
                                        >{i18n.t('home.components.navbar.language')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{
                                                background: '#0e0e0e',
                                            }}
                                        >
                                            <ul>
                                                <li key="item1" style={{display: "flex",padding: '5px 0'}} onClick={() => {
                                                    closeMobileMenu()
                                                    setLanguage('en')
                                                }}>
                                                    <Us />
                                                    <NavbarExternalLink>{i18n.t('home.components.navbar.english')}</NavbarExternalLink>
                                                </li>
                                                <li key="item2" style={{display: "flex",padding: '5px 0'}} onClick={() => {
                                                    closeMobileMenu()
                                                    setLanguage('pt-BR')
                                                }}>
                                                    <Br />
                                                    <NavbarExternalLink>{i18n.t('home.components.navbar.portuguese')}</NavbarExternalLink>
                                                </li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion> 
                                    :
                                    <>
                                    <NavLinks to='contact' >
                                        {i18n.t('home.components.navbar.language')}
                                        <ExpandMoreRounded style={{marginLeft: 4}}/>
                                    </NavLinks>
                                    <ul
                                        className="dropdown"
                                        style={{
                                            display: dropdown? 'block': 'none',
                                            backgroundColor: '#131415',
                                            padding: '20px',
                                            listStyle: 'none',
                                            borderRadius: '5px',
                                            position: 'absolute',
                                        }}
                                    >
                                        <li key="item1" style={{display: "flex",padding: '5px 0'}} onClick={() => {
                                                    setLanguage('en')
                                                }}>
                                            <Us />
                                            <NavbarExternalLink>{i18n.t('home.components.navbar.english')}</NavbarExternalLink>
                                        </li>
                                        <li key="item2" style={{display: "flex",padding: '5px 0'}} onClick={() => {
                                                    setLanguage('pt-BR')
                                                }}>
                                            <Br />
                                            <NavbarExternalLink>{i18n.t('home.components.navbar.portuguese')}</NavbarExternalLink>
                                        </li>
                                    </ul>
                                    </>
                                }
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
