import pt from './default.pt.json';
import en from './default.en.json';

pt['pt-BR'].home = pt['pt-BR'];
en['en'].home = en['en'];


export {
  pt,
  en,
};