import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const HeroSec = styled.div`
padding: 20% 0% 0% 0;
color: #fff;
background: #090909;
position: relative;
`;

export const Container = styled.div`
z-index: 1;
width: 100%;
max-width: 1300px;
margin-right: auto;
margin-left: auto;
padding-right: 50px;
padding-left: 50px;

@media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
}
`;

export const InfoRow = styled.div`
display: flex;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
margin-bottom: 150px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 50%;
flex-basis: 50%;

@media screen and (max-width: 768px) {
    max-width:  100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}
`;

export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`;

export const Img = styled.img`
padding-right: 0;
border-style:solid;
border-image:linear-gradient(#FF1E63, transparent) 1;
border-bottom:0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;
// height: 649px;
// width: 553px;
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 80px;

@media screen and (max-width: 768px) {
    padding-bottom: 65px;
}
`;

export const Heading = styled.h1`
margin-right: 15px;
margin-bottom: 24px;
font-weight: 800;
font-size: 58px;
font-family: poppins,sans-serif;
line-height: 1.1.%;
color: ${({lightText}) => (lightText ? '#FF1E63' : '#FFFFFF')};

@media screen and (max-width: 768px) {
    font-size: 36px;
}
`;

export const Subtitle = styled.p`
max-width: 480px;
margin-bottom: 35px;
font-size: 18px;
font-family: poppins,sans-serif;
line-height: 24px;
text-align: justify;
color: ${({lightTextDesc}) => (lightTextDesc ? '#a9b3c1' : '#ffffff')};
`;

export const TopLine = styled.div`
color: ${({lightTopLine}) => (lightTopLine ? '#a9b3c1' : '#ffffff')};
font-size: 18px;
font-family: poppins,sans-serif;
line-height: 16px;
letter-spacing: 1.4px;
margin-bottom: 16px;

@media screen and (max-width: 768px) {
    margin-top: 80px;
}
`;

export const HeadingRow = styled.h1`
font-weight: 600;
line-height: 60px;
padding-bottom: 4%;
font-size: 136px;
font-family: poppins,sans-serif;
color: ${({lightText}) => (lightText ? '#FF914D' : '#FF914D')};

@media only screen and (max-width: 590px) {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 0;
}

@media only screen and (max-width: 420px) {
    font-size: 64px; 
}
`;

export const BtnLink = styled(LinkS)`
text-decoration: none;
padding-right: 25px;
`;

export const FlexContainer = styled.div`
display: flex;
justify-content: left;
align-items: center;
`; 

export const Button = styled.button`
border-radius: 15px;
width: ${({primary}) => (primary ? '180px' : '120px')};
background: ${({primary}) => (primary ? '#4B59F7' : '#FFFFFF')};
white-space: nowrap;
padding: ${({big}) => (big ? '20px 0px' : '10px 0px')};
color: ${({primary}) => (primary ? '#fff' : '#000')};
font-size: 18px;
font-family: poppins,sans-serif;
font-weight: 500;
outline: none;
border: none;
cursor: pointer;
box-shadow: 0 3px 4px 0 rgba(23,23,23,.24);
display: flex;
justify-content: center;
align-items: center;

&:hover {
    transition: all 0.3s ease-out;
    scale: 1.04;
    background: #fff;
    color: #fff;
    background: ${({primary}) => (primary ? '#0467FB' : '#4B59F7')};
}

@media screen and (max-width: 960px) {
    width: 100%;
    padding-inline: 5px;
}
`;

export const SocialIcons = styled.div`
display: flex;
justify-content: start;
align-items: start;
margin-top: 48px;
width: 240px;
`;

export const SocialIconLink = styled.a`
color: #fff;
font-size: 24px;
margin-right: 25px;
`;