import React from 'react';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Container } from '../../global/styles';
import { FooterLinksContainer,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
    FooterRow,
    FooterWrapper,
    FlexContainer
} from './Footer.elements';

const Footer = () => {

    return (
        <FooterWrapper>
            <Container>
                <FooterRow>
                    <FooterLinksContainer>
                            <SocialLogo to="/" >
                                Felipe Souza
                            </SocialLogo>
                            <FlexContainer>
                            <SocialIcons>
                                <SocialIconLink href="https://github.com/fsouza-fs" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                                    <FaGithub size="35px"/>
                                </SocialIconLink>
                                <SocialIconLink href="https://www.linkedin.com/in/felipe-souza-673b95173/" target="_blank" rel="noopener noreferrer" aria-label="Linkedin">
                                    <FaLinkedin size="35px"/>
                                </SocialIconLink>
                                <SocialIconLink href="https://www.instagram.com/feeh_souza79" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                    <FaInstagram size="35px"/>
                                </SocialIconLink>
                            </SocialIcons>
                            </FlexContainer>
                            <WebsiteRights>
                                Copyright ® {new Date().getFullYear()} - 
                                <a  href="https://www.linkedin.com/in/felipe-souza-673b95173/"
                                    target="_new"
                                    style={{marginLeft: "5px", color: "#fff", fontFamily: "poppins,sans-serif"}}
                                >
                                    Felipe Souza
                                </a>
                                </WebsiteRights>
                    </FooterLinksContainer>
                </FooterRow>
            </Container>
        </FooterWrapper>
    )
}

export default Footer
