import ContactSection from "../../components/contactSection/ContactSection";
import HeroSection from "../../components/heroSection/HeroSection";
import InfoSection from "../../components/infoSection/InfoSection";
import ProjectsSection from "../../components/projectsSection/ProjectsSection";
import ServicesSection from "../../components/servicesSection/servicesSection";
import i18n from "../../components/i18n";

const Home = () => {
    return (
        <>
            <HeroSection />
            <InfoSection 
                imgStart={true} 
                headLine={i18n.t('home.components.infoSection.about')}
                description={i18n.t('home.components.infoSection.description')}
            />
            <ServicesSection /> 
            <ProjectsSection />
            <ContactSection />
        </>
    );
}

export default Home;