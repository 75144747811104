import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const Wrapper = ({children}) => {
    return (
        <>
            <div style={{fontFamily: 'poppins,sans-serif'}}>
                <Navbar />
            </div>
            <main>{children}</main>
            <Footer />
        </>
    );
}

export default Wrapper;