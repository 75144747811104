import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const api = axios.create({
    baseURL: BASE_URL,
    headers: { "x-api-key": `${API_KEY}`}
})

export default api;