import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
    /* overflow-x: hidden;  */
}
`;

export const Container = styled.div`
z-index: 1;
width: 100%;
max-width: 1300px;
margin-right: auto;
margin-left: auto;
padding-right: 50px;
padding-left: 50px;

@media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
}
`;

export const ShapeDivider = styled.div`
    position: absolute;
    ${({top}) => (top ? ' top: 0' : ' bottom: 0')};
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
`;

export const ShapeDividerSVG = styled.svg`
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 49px;
`;

export const ShapeDividerPath = styled.path`
    fill: ${({color}) => (color ? color : '#FF914D')};
`;

export const Button = styled.button`
border-radius: 4px;
background: ${({primary}) => (primary ? '#FF1E63' : '#FF1E63')};
white-space: nowrap;
padding: ${({big}) => (big ? '12px 64px' : '10px 20px')};
color: ${({primary}) => (primary ? '#fff' : '#000')};
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
font-family: poppins,sans-serif;
font-weight: 500;
outline: none;
border: 1px solid #FF1E63;
cursor: pointer;
box-shadow: 0 8px 9px 0 rgba(23,23,23,.24);;

&:hover {
    transition: all 0.3s ease-out;
    background: ${({primary}) => (primary ? '#000' : '#000')};
}

@media screen and (max-width: 960px) {
    width: 100%;
}
`;

export default GlobalStyle;