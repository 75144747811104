import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormSection, InfoColumn, InfoRow, Heading, Subtitle, SocialIcons, SocialIconLink, FlexContainer } from './ContactSection.elements';
import { Container as Wrapper, Button } from '../../global/styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TextField from '../textInput/TextInput.js';
import i18n from '../i18n';
import api from '../../services/api';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '18px',
    borderWidth: '2px',
    borderColor: '#fff',
    padding: '48px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#FF914D',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "& .MuiFilledInput-root": {
        background: "rgb(232, 241, 250)"
    }
  }
}));



export default function ContactSection() {
  const initalState = [
    {
      id: 0,
      name: i18n.t('home.components.contactSection.form.name.title'),
      value: '',
      type: 'text',
      placeholder: i18n.t('home.components.contactSection.form.name.placeholder'),
      errorMessage: "",
      invalid: false,
      required: true
    },
    {
      id: 1,
      name: i18n.t('home.components.contactSection.form.email.title'),
      value: '',
      type: 'text',
      placeholder: i18n.t('home.components.contactSection.form.email.placeholder'),
      errorMessage: "",
      invalid: false,
      required: true,
      validationRegex: '[a-z0-9]+@[a-z]+.[a-z]{2,3}'
    },
    {
      id: 2,
      name: i18n.t('home.components.contactSection.form.subject.title'),
      value: '',
      type: 'text',
      placeholder: i18n.t('home.components.contactSection.form.subject.placeholder'),
      errorMessage: "",
      invalid: false,
      required: true,
    },
    {
      id: 3,
      name: i18n.t('home.components.contactSection.form.message.title'),
      value: '',
      type: 'text',
      placeholder: i18n.t('home.components.contactSection.form.message.placeholder'),
      errorMessage: "",
      invalid: false,
      required: false
    }
  ];

  const classes = useStyles();
  const [inputArray, setInputArray] = useState(initalState);

  async function sendEmail() {
    const name = inputArray[0].value;
    const email = inputArray[1].value;
    const subject = inputArray[2].value;
    const message = inputArray[3].value;

    const data = {
      name,
      email,
      subject,
      message
    }
    
    await api.post('/resource', data)
      .then(response => {
        toast.success(i18n.t('home.components.contactSection.messageReceived'), {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        console.log(error);
        toast.error(i18n.t('home.components.contactSection.error'), {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .finally(() => setInputArray(initalState))
  }

  function handleValidation(e) {
    e.preventDefault();

    const newArray = inputArray.slice();
    newArray.forEach(function(item) {
      if (item.required) {
        let regex = new RegExp(item?.validationRegex);
        if (item.value == null || item.value.trim() === '') {
          item.invalid = true;
        }
        else if (!regex.test(item.value)) {
          item.invalid = true;
          item.errorMessage = `Insert a valid ${item.name}`;
        }
        else {
          item.invalid = false;
          item.errorMessage = '';
        }
      }
      return item;
    });
    setInputArray(newArray);

    if (newArray.find(el => el.invalid === true) === undefined) sendEmail(e);
  }

  function handleChange(event) {
    if (!(event.target.value == null || event.target.value.trim() === '')) {
      const newArray = inputArray.slice();
      newArray[event.target.id].invalid = false;
      setInputArray(newArray);
    }
    const newArray = inputArray.slice();

    newArray[event.target.id].value = DOMPurify.sanitize(event.target.value);
    setInputArray(newArray);
  }

  return (
    <FormSection id="contact">
      <Wrapper>
        <InfoRow>
          <InfoColumn style={{paddingTop: "130px"}}>
              <div style={{height: "6px", backgroundColor: "#FF1E63", width: "120px", marginBottom: "10px"}}/>
              <Heading lightText={false}>{i18n.t('home.components.contactSection.heading')}</Heading>
              <Subtitle lightTextDesc={false}> 
                {i18n.t('home.components.contactSection.subtitle')}
              </Subtitle>
              <FlexContainer>
                <SocialIcons>
                  <SocialIconLink href="https://github.com/fsouza-fs" target="_new" aria-label="GitHub">
                      <FaGithub size="35px"/>
                  </SocialIconLink>
                  <SocialIconLink href="https://www.linkedin.com/in/felipe-souza-673b95173/" target="_new" aria-label="Linkedin">
                      <FaLinkedin size="35px"/>
                  </SocialIconLink>
                  <SocialIconLink href="https://www.instagram.com/feeh_souza79" target="_new" aria-label="Instagram">
                      <FaInstagram size="35px"/>
                  </SocialIconLink>
                </SocialIcons>
                </FlexContainer>
          </InfoColumn>
          <InfoColumn>
            <Container component="main" maxWidth="md">
              <CssBaseline />
              <div className={classes.paper}>
                <form className={classes.form} onSubmit={handleValidation} id="contactForm">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} style={{backgroundColor: '#0e0e0e'}}>
                      <TextField
                        id={inputArray[0].id}
                        title={inputArray[0].name}
                        value={inputArray[0].value}
                        onChange={handleChange}
                        placeholder={inputArray[0].placeholder}
                        required
                        invalid={inputArray[0].invalid}
                        errorMessage={inputArray[0].errorMessage}
                        type={inputArray[0].type}
                        variant="standard"
                        maxLength={50}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={inputArray[1].id}
                        title={inputArray[1].name}
                        value={inputArray[1].value}
                        onChange={handleChange}
                        placeholder={inputArray[1].placeholder}
                        required
                        invalid={inputArray[1].invalid}
                        errorMessage={inputArray[1].errorMessage}
                        type={inputArray[1].type}
                        variant="standard"
                        maxLength={50}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id={inputArray[2].id}
                        title={inputArray[2].name}
                        value={inputArray[2].value}
                        onChange={handleChange}
                        placeholder={inputArray[2].placeholder}
                        required
                        invalid={inputArray[2].invalid}
                        errorMessage={inputArray[2].errorMessage}
                        type={inputArray[2].type}
                        maxLength={50}
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        id={inputArray[3].id}
                        title={inputArray[3].name}
                        value={inputArray[3].value}
                        onChange={handleChange}
                        placeholder={inputArray[3].placeholder}
                        invalid={inputArray[3].invalid}
                        errorMessage={inputArray[3].errorMessage}
                        type={inputArray[3].type}
                        multiline
                        maxLength={240}
                      />
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: "20px"}}>
                      <FormControlLabel
                          control={<Checkbox value="allowExtraEmails" color="primary" style={{color: "#fff"}} required/>}
                          label={i18n.t('home.components.contactSection.agree')}
                          style={{fontFamily: "poppins,sans-serif", color: '#fff'}}
                      />
                      <Link target="_blank" to='/privacy' style={{textDecoration: 'none', fontFamily: "poppins,sans-serif"}}>{i18n.t('home.components.contactSection.test')}</Link>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    big fontBig primary={true} style={{width: "100%"}}
                  >
                    {i18n.t('home.components.contactSection.send')}
                </Button>
                </form>
              </div>
            </Container>
          </InfoColumn>
        </InfoRow>
      </Wrapper>
    </FormSection>
  );
}