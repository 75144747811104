import React from 'react';
import { AllInclusiveOutlined, DesktopMacOutlined, CodeOutlined } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardCost,
  PricingCardLength} from './servicesSection.elements';
  import i18n from '../i18n';

function ServicesSection() {
  return (
    <IconContext.Provider value={{ color: '#3023ae', size: 64 }}>
      <PricingSection id="pricing">
        <PricingWrapper>
          <PricingHeading>{i18n.t('home.components.servicesSection.services')}</PricingHeading>
          <PricingContainer>
            <PricingCard>
              <PricingCardInfo>
                <AllInclusiveOutlined htmlColor='#FF1E63'/>
                <PricingCardCost>{i18n.t('home.components.servicesSection.devops.title')}</PricingCardCost>
                <PricingCardLength>{i18n.t('home.components.servicesSection.devops.description')}</PricingCardLength>
                <Divider />
              </PricingCardInfo>
            </PricingCard>
            <PricingCard>
              <PricingCardInfo>
                <DesktopMacOutlined htmlColor='#FF1E63'/>
                <PricingCardCost>{i18n.t('home.components.servicesSection.frontend.title')}</PricingCardCost>
                <PricingCardLength>{i18n.t('home.components.servicesSection.frontend.description')}</PricingCardLength>
                <Divider />
              </PricingCardInfo>
            </PricingCard>
            <PricingCard>
              <PricingCardInfo>
                <CodeOutlined htmlColor='#FF1E63'/>
                <PricingCardCost>{i18n.t('home.components.servicesSection.backend.title')}</PricingCardCost>
                <PricingCardLength>{i18n.t('home.components.servicesSection.backend.description')}</PricingCardLength>
                <Divider />
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default ServicesSection;