import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const InfoSec = styled.div`
color: #131415;
padding: 160px 0;
background: ${({ lightBg }) => (lightBg ? '#fff' : '#131415')};
position: relative;
`;


export const InfoRow = styled.div`
display: flex;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: flex-start;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 50%;
flex-basis: 50%;

@media screen and (max-width: 768px) {
    max-width:  100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;

@media screen and (max-width: 768px) {
    padding-bottom: 65px;
}
`;

export const TopLine = styled.div`
color: ${({lightTopLine}) => (lightTopLine ? '#ffffff' : '#ffffff')};
font-size: 18px;
font-weight: bold;
font-family: poppins,sans-serif;
line-height: 16px;
letter-spacing: 1.4px;
`;

export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 48px;
font-family: poppins,sans-serif;
line-height: 1.1.%;
color: ${({lightText}) => (lightText ? '#ffffff' : '#ffffff')};
`;

export const Subtitle = styled.p`
max-width: 480px;
font-size: 18px;
font-family: poppins,sans-serif;
// line-height: 24px;
text-align: justify;
// margin-bottom: 16px;
color: ${({lightTextDesc}) => (lightTextDesc ? '#ffffff' : '#ffffff')};
`;

export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`;

export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;
`;

export const BtnLink = styled(LinkS)`
display: flex;
justify-content: flex-start;
align-items: center;
text-decoration: none;
max-width: 480px;
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-bottom: 10px;

@media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    transition: all 0.5s ease;
    align-items: start;
}
`;

export const NavItem = styled.li`
margin-top: 20px;
height: 50px;
font-family: poppins,sans-serif;
font-weight: 500;
align-items: center;

&:hover {
    border-bottom: ${({expanded}) => (expanded ? '2px solid transparent' : '2px solid #FF1E63')};
}

@media screen and (max-width: 960px) {
    width: 100%;
    align-items: start;
    border-bottom: none;

    &:hover {
        border: none;
    }
}
`;

export const NavLinks = styled(LinkS)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem 0rem;
margin-right: 1rem;
height: 100%;
cursor: pointer;
font-family: poppins,sans-serif;
color: ${({selected}) => (selected ? '#FF1E63' : '#FFFFFF')};
font-size: 18px;
font-weight: bold;

@media screen and (max-width: 960px) {
    text-align: start;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
        color: #FF1E63;
        transition: all 0.3s ease;
    }
}
`;